.ask-question-btn {
    width: 200px;
    height: 50px;
    font-size: @font-size-s;
}
.ask-question-btn.active {
    color: @primary-color;
    border: solid 1px #e2e2e2 !important;
}
.ask-question-btn.not-active {
    color: @text-color-secondary-dark;
    border: solid 1px #e2e2e2 !important;
}
.discussion_dropdown_icon {
    border: 0;
    outline: 0;
    border-radius: 50%;
    background-color: inherit;
}
.discussion_dropdown_icon:hover {
    background-color: @summary-component-background-color;
}
