body {
    background-color: @primary-light-color;
}
// Heading
.home-head-content-wrapper {
    height: calc(100vh - 60px);
}
.home-head-tag-wrapper {
    max-width: 600px;
}
.main-heading {
    font-size: @font-size-xxxl;
    font-weight: @font-weight-500;
    letter-spacing: 1px;
}
.home-heading-subtitle {
    font-size: @font-size-l;
    letter-spacing: 0.5px;
}
.home-button {
    width: 300px;
    height: 65px;
    letter-spacing: 0.5px;
    border-radius: 50px;
}
.home-head-button {
    border: none;
    font-size: @font-size-l;
    color: #121212;
    background-color: transparent;
    &:hover {
        background-color: transparent;
        color: #121212;
    }
    &:focus {
        background-color: transparent;
        color: #121212;
    }
}
.home-head-button a:hover,
.home-head-button a:focus {
    color: #121212;
}
.home-button-arrow {
    font-size: @font-size-xl;
}
.home-head-side-wrapper {
    max-width: 800px;
}

.home-head-side {
    width: 40vw;
    height: 28vw;
}
.home-head-background {
    top: 15px;
    left: -15px;
    right: 15px;
    bottom: -15px;
    background-color: @secondary-light-color;
    border-radius: @border-radius-base;
    transform: rotate(-1deg);
}
.home-head-foreground {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @primary-dark-color;
    border-radius: @border-radius-base;
    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: @primary-dark-color;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: @scrollbar-color;
        border-radius: 10px;
    }
}
// Common properties
.dark-bg-wrapper {
    background-color: #ededed;
}
.home-heading {
    font-size: @font-size-xxl;
    letter-spacing: 1px;
}
// Courses
.home-courses-inner-wrapper {
    flex-wrap: wrap;
    align-content: space-around;
}
.home-course-wrapper {
    margin: 0 4% 32px;
    flex: 1 1 0px;
    max-width: 420px;
    background-color: @primary-light-color;
}
.course-card {
}
.home-course-card-img-wrapper {
    text-align: center;
    height: 120px;
    border-radius: @border-radius-base @border-radius-base 0 0;
}
.home-course-card-content-wrapper {
    padding-top: 0;
    padding-bottom: calc((@padding-l + @padding-xl) / 2);
}
.home-course-progress {
    top: 2%;
    right: 2%;
}
.home-course-card-heading-wrapper {
    margin-top: -10px;
}
.home-course-card-heading {
    font-size: @font-size-l;
    letter-spacing: 0.8px;
    border-radius: @border-radius-base;
    background-color: @primary-light-color;
    border: @border-dark;
}
.home-courses-card-button-wrapper {
    justify-content: space-between;
}
.course-card-button {
    font-weight: 500;
    letter-spacing: 0.2px;
    &:hover {
        background-color: darken(@primary-color, 5%) !important;
        border-color: darken(@primary-color, 5%) !important;
        color: @white-color;
    }
}
.home-course-card-subdetail-icon {
    width: @font-size-s;
    font-size: @font-size-s;
    color: @primary-color;
}
.home-course-card-text {
    margin-left: 15px;
    font-size: calc((@font-size-xs + @font-size-s) / 2);
    color: @text-color-secondary-medium;
    letter-spacing: 0.3px;
}
.home-course-card-price-wrapper {
    padding: @padding-s @padding;
    background-color: @secondary-color;
    border: solid 2px darken(@secondary-color, 5%);
    border-radius: @border-radius-base;
    margin-left: auto;
    margin-right: auto;
}
.home-course-card-price-currency {
    font-size: @font-size-s;
}
.home-course-card-price-money {
    font-size: @font-size-s;
    font-weight: @font-weight-700;
}
.home-course-card-original-price {
    font-size: @font-size-xs;
    color: @text-color-secondary-light;
    text-decoration: line-through;
    letter-spacing: 0.5px;
}
.course-card-button {
    font-size: @font-size-xs;
}
.home-card-course-free-button,
.home-card-course-content-button {
    border-color: @primary-color;
}
.home-card-course-buy-button {
    background-color: @primary-color;
    border-color: @primary-color;
    color: @white-color;
}
//why us
.why-us-wrapper {
    background: linear-gradient(
        326deg,
        rgba(128, 43, 177, 1) 0%,
        rgba(102, 31, 152, 1) 50%,
        rgba(103, 39, 164, 1) 100%
    );
}
.why-us-inner-wrapper {
    width: 90%;
}
.why-us-component {
    flex: 23%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    h3 {
        font-size: @font-size-xlp;
        font-weight: @font-weight-400;
        letter-spacing: 0.2px;
        color: white;
    }
    p {
        font-size: @font-size-l;
        color: @darker-white-color;
        letter-spacing: 0.1px;
    }
    .why-us-img-circle {
        width: 56px;
        height: 56px;
        display: inline-block;
        border-radius: 50%;
    }
    .why-us-img {
        margin-top: -50px;
        font-size: 42px;
    }
}
.why-us-simplicity {
    .why-us-img-circle {
        background-color: fade(#278cb4, 35%);
    }
    .why-us-img {
        color: #278cb4;
    }
}
.why-us-quality {
    margin-top: 50px;
    .why-us-img-circle {
        background-color: fade(#e68618, 30%);
    }
    .why-us-img {
        color: #e68618;
    }
}
.why-us-doubt {
    .why-us-img-circle {
        background-color: fade(#56bd27, 35%);
    }
    .why-us-img {
        color: #56bd27;
    }
}
.why-us-faculty {
    margin-top: 50px;
    .why-us-img-circle {
        background-color: fade(#ea64f7, 35%);
    }
    .why-us-img {
        color: #ea64f7;
    }
}
@media (max-width: @breakpoint-tab) {
    .why-us-component {
        flex: 45%;
    }
    .why-us-quality,
    .why-us-faculty {
        margin-top: 0;
    }
}
@media (max-width: @breakpoint-mob) {
    .why-us-component {
        flex: 70%;
    }
    .why-us-quality,
    .why-us-faculty {
        margin-top: 0;
    }
}

// testimonials
.testimonial {
    max-width: 500px;
    width: 90%;
    min-width: 200px;
    background-color: white;
    border-radius: @border-radius-base;
    filter: @element-non-linear-shadow;
}
.testimonial-quote {
    font-size: @font-size-xxxl;
    top: -1.2rem;
    right: 10px;
}
.testimonial-text {
    font-size: @font-size-l;
    font-weight: @font-weight-500;
    letter-spacing: 0.1px;
}
.testimonial-footer {
    border-radius: 0 0 @border-radius-base @border-radius-base;
}
.testimonial-author {
    font-size: @font-size-s;
    color: @white-color;
    font-weight: @font-weight-500;
    letter-spacing: 0.01rem;
}
.testimonial-author-info {
    font-size: @font-size-xs;
    color: @darker-white-color;
    font-weight: @font-weight-500;
    letter-spacing: 0.01rem;
}
.testimonial-component1 {
    .testimonial {
        transform: rotate(0.8deg);
    }
    .testimonial-quote {
        color: lighten(#296b0a, 25%);
    }
    .testimonial-footer {
        background-image: linear-gradient(to bottom right, lighten(#296b0a, 25%), #0d8571);
    }
}
.testimonial-component2 {
    .testimonial {
        transform: rotate(-4deg);
    }
    .testimonial-quote {
        color: lighten(#d87a0e, 25%);
    }
    .testimonial-footer {
        background-image: linear-gradient(to bottom right, lighten(#d87a0e, 25%), #cc5d13);
    }
}
.testimonial-component3 {
    .testimonial {
        transform: rotate(1deg);
    }
    .testimonial-quote {
        color: lighten(#186786, 25%);
    }
    .testimonial-footer {
        background-image: linear-gradient(to bottom right, lighten(#186786, 25%), #185a86);
    }
}
.testimonial-component4 {
    .testimonial {
        transform: rotate(-2deg);
    }
    .testimonial-quote {
        color: lighten(#a62bb1, 25%);
    }
    .testimonial-footer {
        background-image: linear-gradient(to bottom right, lighten(#a62bb1, 25%), @primary-color);
    }
}

// features
.home-benifits-content-wrapper {
    width: 90%;
    max-width: 2000px;
    min-width: 310px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 30px;
    margin-left: auto;
    margin-right: auto;
}
.benifit-wrapper {
    width: 88%;
    max-width: 420px;
    min-width: 280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: @primary-light-color;
    border-radius: @border-radius-base;
}
.benifit {
    display: flex;
}
.benifit-img-wrapper {
    position: absolute;
    top: -40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    font-size: 26px;
    color: darken(@secondary-color, 10%);
    background-color: white;
}
.benifit-img-wrapper1 {
    text-align: center;
    border-top-left-radius: @border-radius-base;
    border-top-right-radius: @border-radius-base;
    background: linear-gradient(180deg, rgba(128, 43, 177, 1) 0%, rgba(108, 58, 172, 1) 100%);
}
.benifit-img-wrapper1 img {
    height: 60px;
}
.benifit-detail-wrapper {
    width: 100%;
}
.benifit-title {
    font-size: calc((@font-size-l + @font-size-xl) / 2);
    font-weight: @font-weight-500;
}
.benifit-detail {
    font-size: @font-size-s;
    color: @text-color-secondary-medium;
}
.home-internship-tnc {
    font-size: @font-size-xs;
}
@media (max-width: @breakpoint-screen-s) {
    .home-benifits-content-wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
    }
}
@media (max-width: @breakpoint-tab) {
    .home-benifits-content-wrapper {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 10px;
    }
}
// doubt solving
.doubt-solving-inner-wrapper {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: fade(@primary-color, 90%);
}
.doubt-solving-inner-wrapper h2 {
    font-size: @font-size-xxl;
    letter-spacing: 1px;
    color: white;
}
.doubt-solving-inner-wrapper p {
    font-size: @base-font-size;
    letter-spacing: 0.5px;
    color: white;
}
// Steps
.step-wrapper {
    justify-content: space-around;
}
.step {
    margin-left: 2%;
    margin-right: 2%;
    max-width: 350px;
    padding: @padding-xxl @padding-xxl @padding-l;
    position: relative;
    flex: 1;
}
.step-index {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -10px;
    margin-left: -10px;
    background-color: @secondary-color;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: @font-size-xl;
    font-weight: @font-weight-900;
    border: solid 1px #e0e0e0;
    box-shadow: -0.1em -0.1em 0.2em #dedede;
}
.step img {
    width: 100%;
    height: max(
        calc(1.45 * 1 / 3 * 94vw - 2 * @padding-xxxl - 6 * @padding-xxl),
        calc(241 - 2 * @padding-xxl)
    ); // aspect ratio of img: 1.45
}
.step-detail {
    font-size: @font-size-l;
    letter-spacing: 0.5px;
}
// Join Now
.home-tail-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -@margin-xxxl;
    background: radial-gradient(circle, rgba(128, 43, 177, 1) 0%, rgba(109, 26, 156, 1) 34%);
}
.home-tail-content-wrapper h2 {
    font-size: @font-size-xxl;
    font-weight: @font-weight-700;
    letter-spacing: 1px;
    color: white;
}
.home-tail-content-wrapper p {
    font-size: @font-size-l;
    letter-spacing: 0.2px;
    color: white;
}
.home-tail-button {
    font-size: @base-font-size;
    font-weight: @font-weight-500;
    background-color: @secondary-color;
    color: @text-color-secondary-dark;
}
.home-tail-button:hover {
    background-color: darken(@secondary-color, 2%);
    color: @text-color-secondary-dark;
}
// Media
@media (min-height: 1000px) {
    .home-head-content-wrapper {
        height: 950px;
    }
    .home-course-wrapper {
        max-width: 410px;
    }
}
@media (max-width: @breakpoint-tab) {
    .home-button {
        margin-left: auto;
        margin-right: auto;
    }
    .home-head-tag-wrapper {
        padding-right: 0;
    }
    .benifit-wrapper {
        flex: 100%;
    }
    .doubt-solving-wrapper {
        background: none;
    }
}
@media (max-width: @breakpoint-mob) {
    .home-card-course-buy-button,
    .home-card-course-content-button {
        margin-top: @margin;
    }
    .home-card-course-buy-button {
        margin-right: @margin;
    }
    .benifit {
        flex-wrap: wrap;
    }
}
@media (max-width: @breakpoint-mob) and (min-height: 800px) {
    .home-head-content-wrapper {
        height: 750px;
    }
}
// misc
@media screen and (min-width: 1601px) {
    .step {
        margin-left: 0.5%;
        margin-right: 0.5%;
        max-width: 450px;
    }
}
@media (max-width: 1000px) {
    .step-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
    }
}
@media (max-width: 370px) {
    .home-button {
        width: 250px;
    }
}

// Editor
.home-editor-navbar {
    border-bottom: solid 2px lighten(@primary-dark-color, 10%);
    padding: 10px 15px;
    div {
        width: 12px;
        height: 12px;
        margin-right: 7px;
        border-radius: 50%;
    }
    .close {
        border: 2px solid rgb(245, 60, 4);
    }
    .minimize {
        border: 2px solid rgb(255, 217, 0);
    }
    .maximize {
        border: 2px solid rgb(19, 161, 30);
    }
}
.home-editor-body {
    padding: 10px 12px;
    font-family: @font-family-monospace;
    font-size: @font-size-s;
    font-weight: @font-weight-400;
    color: @code-text-color;
    height: calc(100% - 55px);
    overflow-y: scroll;
    .home-editor-line-parent {
        height: calc(@font-size-s * 1.8);
    }
    .home-editor-text {
        white-space: nowrap;
        overflow: hidden;
        padding-right: 3px;
    }
    .home-editor-var-text {
        margin-left: 4px;
    }
    .editor-cursor {
        border-right: solid 9px rgba(255, 255, 255, 0.75);
    }
}

.highlight-primary {
    background-color: @primary-color;
    color: @white-color;
    border-radius: @border-radius-base;
}

@keyframes animated-cursor {
    0% {
        border-right-color: transparent;
    }
    1% {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    50% {
        border-right-color: rgba(255, 255, 255, 0.75);
    }
    51% {
        border-right-color: transparent;
    }
    100% {
        border-right-color: transparent;
    }
}

.editor-cursor-end {
    animation: animated-cursor 1200ms linear infinite;
}
