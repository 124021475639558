.preview-content {
    white-space: normal;
}
.preview-content h1 {
    font-size: 1.5rem;
    margin-top: 1.1rem;
    margin-bottom: 0.7rem;
}
.preview-content h2 {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 0.56rem;
}
.preview-content h3 {
    font-size: 1.1rem;
    margin-top: 0.9rem;
    margin-bottom: 0.5rem;
}
.preview-content h4 {
    font-size: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.46rem;
}
.preview-content h5 {
    font-size: 0.92rem;
    margin-top: 0.8rem;
    margin-bottom: 0.45rem;
}
.preview-content h6 {
    font-size: 0.85rem;
    margin-top: 0.8rem;
    margin-bottom: 0.45rem;
}
.preview-content h1,
.preview-content h2,
.preview-content h3,
.preview-content h4,
.preview-content h5,
.preview-content h6 {
    color: @primary-dark-color;
}
.preview-content p {
    margin-bottom: 0.8rem;
    line-height: 1.2rem;
    font-size: 0.78rem;
    color: @text-color-secondary-medium;
    letter-spacing: 0.1px;
    white-space: normal;
}
.preview-content s {
    text-decoration-thickness: 2px;
}
.preview-content blockquote {
    margin: 1rem 0;
    padding: 0.45rem 0.62rem;
    border-left: solid 5px @secondary-light-color;
}
.preview-content blockquote p {
    margin-bottom: 0;
    color: @text-color-secondary-light;
}
.preview-content code {
    display: inline-block;
    margin-top: -1px;
    padding: 3px 8px;
    font-family: @font-family-monospace;
    font-size: 0.79rem;
    color: darken(@secondary-color, 25%);
    background-color: lighten(@secondary-color, 41%);
    border: @summary-element-border;
    border-radius: 4px;
}
.preview-content pre {
    margin: 0.5rem 0;
    padding: 0.5rem;
    background-color: @code-background-color-secondary;
    border: @summary-element-border;
    border-radius: 4px;
}
.preview-content pre code {
    color: @text-color-secondary-dark;
    font-size: 0.75rem;
    background-color: inherit;
    border: 0;
}
.preview-content hr {
    margin: 0.8rem 0;
    border-top: @border-hr-light;
}
.preview-content a {
    text-decoration: none;
    box-shadow: @text-underline-box-shadow;
    color: @text-color-secondary-light;
}
.preview-content a:hover {
    box-shadow: @text-underline-box-shadow-hover;
}
.preview-content img {
    max-width: 100%;
    margin: 0.5rem 0;
}
.preview-content table {
    min-width: 50%;
    max-width: 100%;
    margin: 0.8rem auto;
    overflow: auto;
    border: none;
    border-collapse: separate !important;
    border-spacing: 0;
    border-radius: @border-radius-base;
    tr:first-child {
        th:first-child {
            border-radius: @border-radius-base 0 0 0;
        }
    }

    tr:first-child {
        th:last-child {
            border-radius: 0 @border-radius-base 0 0;
        }
    }
    tr:last-child {
        td:first-child {
            border-radius: 0 0 0 @border-radius-base;
        }
    }

    tr:last-child {
        td:last-child {
            border-radius: 0 0 @border-radius-base 0;
        }
    }
    tr:hover {
        background-color: darken(@primary-light-color, 2%);
    }
    td {
        padding: 0.2rem 0.5rem;
        font-size: 0.79rem;
        color: @text-color-secondary-medium;
        border: @border-table-light;
    }
    th {
        padding: 0.2rem 0.5rem;
        font-size: 0.8rem;
        letter-spacing: 0.4px;
        font-weight: @font-weight-500;
        color: @white-color;
        background-color: @primary-color;
        border: @border-table-header;
    }
}
.preview-content ul {
    margin: 0.5rem 0;
    ul {
        margin: 0.2rem 0;
    }
    ol {
        margin: 0.2rem 0;
    }
}
.preview-content ol {
    margin: 0.5rem 0;
    ul {
        margin: 0.2rem 0;
    }
    ol {
        margin: 0.2rem 0;
    }
}
.preview-content li {
    margin-bottom: 0.1rem;
    font-size: 0.78rem;
    color: @text-color-secondary-medium;
    letter-spacing: 0.1px;
}
