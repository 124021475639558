.login-social-btn {
    margin-right: auto !important;
    margin-left: auto !important;
    border: solid 1px #f2f2f2 !important;
}
.login-social-btn:focus {
    outline-color: white;
}
.login-social-btn-text {
    font-size: 15px;
    font-weight: @font-weight-500;
}
// Google
.login-social-btn > div {
    display: flex;
    align-items: center;
}
