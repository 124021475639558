// alerts on enrolled/pending status
.status-alert-text {
    max-width: 500px;
    font-size: @font-size-xl !important;
    font-weight: @font-weight-500 !important;
}
// address form
.payment-form {
    width: 70%;
    min-width: 300px;
    max-width: 740px;
}
.payment-form .form-item * {
    font-size: @font-size-xs !important;
}
.payment-form .form-input {
    width: 100% !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.payment-form .ant-select {
    padding-top: 4.5px !important;
    padding-bottom: 4.5px !important;
}
.phone-input {
    width: 100% !important;
    height: 41px !important;
    background-color: @primary-light-color !important;
    border: @border-input !important;
}
.phone-input:hover {
    border: solid 1px @primary-color !important;
}
.react-tel-input:hover .flag-dropdown {
    transition: all 0.3s;
    border: solid 1px @primary-color !important;
}
.phone-input:focus .flag-dropdown,
.phone-input:active .flag-dropdown {
    border: solid 1px @primary-color !important;
}
// payment step 1 (address cards)
.billing-detail-card {
    overflow: hidden;
}
.billing-card-inner-wrapper {
    width: 278px;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.billing-detail-card-name {
    font-size: 110%;
}
.billimg-detail-btn-wrapper {
    margin-top: auto;
}
.billing-detail-card-btn {
    letter-spacing: 0.3px;
}
.billing-detail-card-btn:hover {
    color: @text-color-secondary-dark;
}
.edit-billing-detail-btn {
    width: 65px;
}
.select-billing-detail-btn {
    width: 170px;
}
.add-billing-detail-icon {
    font-size: @font-size-xxxxl;
    color: @text-color-secondary-extra-light;
}
.add-billing-detail-text {
    font-size: 130%;
}
.selected_billing-detail-card {
    border: solid 2px @primary-color !important;
}
// payment step 2
.payment-alert {
    width: 80%;
    font-size: @font-size-s;
}
.selected-billing-details-card {
    flex: 0 0 66%;
    min-width: 300px;
    max-width: 710px;
}
.payment-course-img-wrapper {
    img {
        width: 75px;
        height: 30px;
    }
}
.payment-course-name {
    font-size: @font-size-l;
}
.payment-course-language {
    font-size: @font-size-s;
}
.payment-course-language:not(:last-of-type)::after {
    content: ', ';
}
.payment-course-detail {
    .course-detail-value {
        font-size: @font-size-s;
        font-weight: 500;
        color: @primary-color;
    }
    .course-detail-key {
        font-size: @font-size-xs;
    }
}
.payment-course-feature {
    font-size: @font-size-xs;
}
.payment-summary-card {
    flex: 0 0 50%;
    min-width: 250px;
    max-width: 365px;
}
.selected-detail-label {
    font-size: @font-size-s;
    font-weight: @font-weight-500;
}
.selected-detail-value {
    font-size: @font-size-s;
}
.billing-detail-price {
    font-size: @font-size-s;
}
.billing-total-price {
    font-size: @font-size-l;
}
.billing-detail-hr {
    border-top: @border-hr;
}
// payment
.payment-btn {
    width: 100%;
    height: 51px;
    font-size: @font-size-l;
    color: @white-color;
    letter-spacing: 0.5px;
    background-color: @primary-color;
    border-color: @primary-color !important;
    &:hover {
        background-color: darken(@primary-color, 5%) !important;
        border-color: darken(@primary-color, 5%) !important;
        color: @white-color;
    }
    &:focus {
        background-color: darken(@primary-color, 5%) !important;
        border-color: darken(@primary-color, 5%) !important;
        color: @white-color;
    }
}
.payment-btn[disabled],
.payment-btn[disabled]:hover,
.payment-btn[disabled]:focus,
.payment-btn[disabled]:active {
    color: @disabled-btn-color;
    background-color: @disabled-btn-bg-color !important;
    border: @disabled-btn-border !important;
}
.referral-code-text {
    font-size: @font-size-l;
}
.referral-code-success-text {
    font-size: @font-size-s;
}
.use-credits-text {
    font-size: @font-size-s;
}
.available-credits-text {
    font-size: @font-size-xs;
    color: @text-color-secondary-light;
}
.payment-prev-arrow {
    margin-left: 10%;
    font-size: @font-size-xxl;
    color: @primary-color;
}
.payment-prev-text {
    font-size: @font-size-l;
}
// payment button in small screens
.fixed-payment-btn {
    position: fixed;
    border-top: @border-input;
    background-color: @primary-light-color;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    .payment-btn {
        width: 70%;
    }
}
// media queries
@media (max-width: @breakpoint-mob) {
    .payment-alert {
        width: 100%;
    }
}
@media (max-width: 350px) {
    .edit-billing-detail-btn {
        width: 55px;
    }
    .select-billing-detail-btn {
        width: 150px;
    }
}
