// yellow underline
.span-underline {
    box-shadow: inset 0 -0.8rem @secondary-color, inset 0 0 @secondary-color;
}
// dotted bg
.dotted-bg {
    position: absolute;
    left: 5%;
    top: 7%;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(@primary-color 20%, transparent 20%), radial-gradient(@primary-color 20%, transparent 20%);
    background-color: transparent;
    background-position: 0 0, 50px 50px;
    background-size: 10px 10px;
}