.language-button {
    min-height: 100%;
    padding: @padding-s @padding;
}
.language-button .ant-select-borderless,
.language-button .ant-select {
    border: none !important;
}
.language-button-text {
    font-weight: @font-weight-500;
    font-size: calc((@font-size-xs + @font-size-s) / 2);
}
