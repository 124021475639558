.not-found-heading {
    font-size: @font-size-xxl;
}
.not-found-text {
    font-size: @font-size-xl;
}
.not-found-button {
    width: 240px;
    height: 50px;
    font-size: @font-size-l;
    letter-spacing: 0.5px;
}
