.our-mission-wrapper {
    text-align: center;
    h1 {
        font-size: @font-size-xxxl;
    }
    p {
        padding-right: 20%;
        padding-left: 20%;
        font-size: @font-size-xl;
        background-color: @primary-color;
        color: white;
    }
    .mission-quote {
        font-size: @font-size-xxxl;
    }
}
.about-steps-wrapper {
    max-width: 1400px;
}
.about-steps {
    width: calc(100% / 3);
    min-width: min(100%, 330px);
    padding-right: @padding-l;
    padding-left: @padding-l;
    text-align: center;
}
.about-steps img {
    width: 70%;
}
.about-steps h3 {
    font-size: @font-size-xl;
    font-weight: 700;
    letter-spacing: 0.4px;
}
.about-steps p {
    font-size: calc((@font-size-s + @font-size-m) / 2);
}
.about-heading {
    font-size: @font-size-xxl;
}
// what we provide
.about-features-wrapper {
    width: 90%;
    max-width: 2000px;
    min-width: 310px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 30px;
    margin-left: auto;
    margin-right: auto;
}
.about-feature-wrapper {
    width: 88%;
    max-width: 420px;
    min-width: 280px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: white;
    border-radius: @border-radius-base;
}
.about-feature-img-wrapper {
    width: 60px;
    height: 60px;
    text-align: center;
    background: linear-gradient(180deg, rgba(128, 43, 177, 1) 0%, rgba(108, 58, 172, 1) 100%);
}
.about-feature-icon {
    font-size: 40px;
    color: @primary-color;
}
.about-feature-detail-wrapper {
    width: 100%;
}
.about-feature-title {
    font-size: calc((@font-size-l + @font-size-xl) / 2);
    font-weight: @font-weight-500;
}
.about-feature-detail {
    font-size: @font-size-s;
    color: @text-color-secondary-medium;
}
@media (max-width: @breakpoint-screen-s) {
    .about-features-wrapper {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 10px;
    }
}
@media (max-width: @breakpoint-tab) {
    .about-features-wrapper {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 10px;
    }
}
