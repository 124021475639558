.hash {
    font-size: @font-size-xl;
    color: darken(@secondary-color, 15%);
}
.practice-ques-sep-wrapper {
    width: 80%;
}
.practice-section-ans {
    border-top: @border-hr;
}
.practice-section-ans h2 {
    margin-bottom: 10px !important;
}
.practice-ques-sep-wrapper h1 {
    margin-bottom: @margin-l;
    font-size: calc(@font-size-xl + (@font-size-xxl - @font-size-xl) / 2);
    letter-spacing: 0.3px;
}
.practice-ques-sep-wrapper h2 {
    display: inline-block;
    margin-bottom: @margin-l;
    margin-left: @margin;
    font-size: @font-size-xl;
    letter-spacing: 0.3px;
}
.practice-ques-sep-wrapper h3 {
    margin-top: @margin-l;
    font-size: @font-size-l;
    letter-spacing: 0.3px;
}
.practice-ques-sep-wrapper h4 {
    font-size: @font-size-s;
}
.practice-ques-sep-wrapper .code-block {
    padding: @padding @padding-l;
    font-size: @font-size-xs;
    background-color: @darker-white-color;
    border: solid 1px darken(@darker-white-color, 20%);
}
.practice-eg-explanation {
    white-space: pre;
    overflow: scroll;
}
.practice-ques-sep-wrapper p {
    font-size: @font-size-s;
    letter-spacing: 0.1px;
}
