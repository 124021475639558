.top-banner-wrapper {
    background-color: @secondary-color;
    box-shadow: 0 1px 7px 2px rgba(0, 0, 0, 0.2);
    color: @text-color-secondary-dark;
}
.top-banner-offer {
    font-size: @font-size-xxxl;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}
.top-banner-offer-subtext {
    font-size: @font-size-xl;
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
    text-shadow: -1px 1px 2px #000, 1px 1px 2px #000, 1px -1px 0 #000, -1px -1px 0 #000;
}
.top-banner-offer-text-line1 {
    font-size: calc((@font-size-l + @font-size-xl) / 2);
    font-weight: 500;
}
.top-banner-offer-text-line2 {
    margin-top: -2px;
    font-size: @font-size-l;
    color: @text-color-secondary-medium;
    font-weight: 700;
    letter-spacing: 0.2px;
    word-spacing: 2px;
}
.top-banner-offer-text-line3 {
    margin-top: -2px;
    font-size: @font-size-l;
    color: @text-color-secondary-medium;
    font-weight: 700;
    letter-spacing: 0.3px;
    word-spacing: 5px;
    text-transform: uppercase;
}
// counter
#countdown {
    margin-top: -1px;
    text-align: center;
    letter-spacing: 1px;
}
#countdown ul {
    padding-left: 0;
    margin: 0;
}
#countdown li {
    display: inline-block;
    font-size: @font-size-xxs;
    font-weight: 500;
    list-style-type: none;
    padding: 5px;
    text-transform: uppercase;
}
#countdown li span {
    display: block;
    font-size: calc((@font-size-l + @font-size-xl) / 2);
    color: #802bb1;
    font-weight: 700;
}
