// Course Menu
.course-content-menu-wrapper {
    flex: 28%;
    min-width: @left-menu-min-width;
    max-width: @left-menu-max-width;
}
.course-content-menu-wrapper-collapsed {
    flex: 5%;
    position: fixed;
    z-index: 1;
}
.course-content-menu {
    height: calc(100vh - @padding-l - 60px - 32px);
    overflow: auto;
}
/* scrollbar width */
.course-content-menu::-webkit-scrollbar {
    width: 0px;
}

.menu-collapsed {
    width: 0;
}
.menu-inline {
    width: 95%;
}
.vertical-padding {
    padding-top: @padding !important;
    padding-bottom: @padding !important;
}
.course-content-menu .ant-menu-inline-collapsed {
    width: 0 !important;
}
.course-content-menu .course-content-menu-li {
    border-bottom: @border-hr;
}
.course-content-menu .course-content-menu-li:last-of-type {
    border-bottom: 0;
}
.course-content-menu * {
    white-space: normal;
    line-height: 1.5;
}
.course-content-menu-title-text,
.course-content-menu-item,
.course-content-menu-subtitle-text {
    display: block;
}
.course-content-menu-title-text {
    font-weight: @font-weight-500;
    font-size: 15px;
    color: @text-color-secondary-medium;
}
.course-content-time {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: @text-color-secondary-light;
}
.course-content-type {
    color: rgba(0, 0, 0, 0.4);
}
.ant-menu-submenu-selected .ant-menu-submenu-arrow {
    color: @primary-color !important;
}
.ant-menu-submenu-selected .course-content-menu-title {
    color: @primary-color !important;
}
// Video
.course-content {
    max-width: 100%;
    width: 72%;
    flex: 72%;
}
.course-content iframe {
    width: 100%;
    height: 100%;
    -webkit-transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
// Tabs
.course-content-tabs-wrapper {
    justify-content: flex-end;
}
.course-content-tabs-wrapper .ant-menu-inline-collapsed {
    width: 50px;
}
.course-content-tabs-mob .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0 10px;
    padding: 0;
}
// Media
@media (max-width: calc((@left-menu-max-width * 100) / 28)) {
    .course-content {
        width: calc(100% - 265px - 60px);
    }
}
@media (max-width: @breakpoint-tab) {
    .course-content-wrapper {
        position: relative;
    }
    .course-content-menu-wrapper {
        position: fixed;
        z-index: 1;
        min-width: 0;
    }
    .course-content-menu {
        box-shadow: none !important;
        height: calc(100vh - 45px - 50px - @padding-l - 32px);
        overflow: auto;
    }
    .course-content {
        width: 100%;
    }
}
