// card
.default-card-neu {
    border-radius: @border-radius-base;
    border: solid 1px #e2e2e2;
    box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 0.9em #efefef, 0.3em 0.3em 0.7em #c8c8c8,
        inset -0.4em -0.5em 0.9em #efefef !important;
}
// card without inset shadow (for colored cards)
.colored-card-neu {
    border-radius: @border-radius-base;
    border: solid 1px #e1e1e1;
    box-shadow: -0.5em -0.4em 0.5em #fff, 0.3em 0.3em 0.7em #c8c8c8 !important;
}
// small card
.small-card-neu {
    border-radius: @border-radius-base;
    border: solid 1px #e1e1e1;
    box-shadow: -0.3em -0.2em 0.2em #fff, inset 0.4em 0.5em 0.9em #ededed, 0.2em 0.2em 0.3em #c5c5c5,
        inset -0.4em -0.5em 0.9em #ededed !important;
}
// small card with inset hover effect
.small-card-neu-hover {
    border-radius: @border-radius-base;
    border: solid 1px #e1e1e1;
    box-shadow: -0.3em -0.2em 0.2em #fff, inset 0.4em 0.5em 0.9em #ededed, 0.2em 0.2em 0.3em #c5c5c5,
        inset -0.4em -0.5em 0.9em #ededed !important;
    &:hover {
        border: solid 1px #efefef !important;
        box-shadow: #bdbdbd 2px 2px 4px 1px inset, #ffffff -2px -2px 3px 4px inset !important;
    }
}
// neumorphic shadow
.shadow-neu {
    box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 0.9em #efefef, 0.3em 0.3em 0.7em #c8c8c8,
        inset -0.4em -0.5em 0.9em #efefef;
}
// neumorphic shadow (small elements)
.small-shadow-neu {
    box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 0.9em #efefef, 0.3em 0.3em 0.7em #c8c8c8,
        inset -0.4em -0.5em 0.9em #efefef;
    border: solid 1px #e1e1e1;
}
// neumorphic shadow (small colored elements)
.colored-small-shadow-neu {
    box-shadow: -0.3em -0.2em 0.3em #fff, 0.2em 0.2em 0.3em #cfcfcf;
    border: solid 1px #e1e1e1;
}
// neumorphic shadow (very small elements)
.extra-small-shadow-neu {
    box-shadow: -0.1em -0.1em 0.1em #fff, inset 0.1em 0.2em 0.2em #efefef, 0.1em 0.1em 0.2em #c5c5c5,
        inset -0.1em -0.1em 0.1em #efefef;
    border: solid 1px #e1e1e1;
}
// button with inset shadow and hover (for white colored buttons with rounded corners)
.home-button-neu {
    box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 0.9em #efefef, 0.5em 0.4em 0.9em #c8c8c8,
        inset -0.4em -0.5em 0.9em #efefef;
    border-radius: 50px;
    &:hover {
        box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 1.5em #eaeaea, 0.5em 0.4em 0.7em #c2c2c2,
            inset -0.4em -0.5em 1.5em #eaeaea;
    }
    &:focus {
        box-shadow: -0.5em -0.4em 0.5em #fff, inset 0.4em 0.5em 1.5em #e2e2e2, 0.5em 0.4em 0.7em #bdbdbd,
            inset -0.4em -0.5em 1.5em #e2e2e2;
    }
}
// button without inset shadow (for colored buttons)
.home-button-primary-color {
    box-shadow: -0.5em -0.4em 0.5em #fff, 0.5em 0.4em 0.9em #c8c8c8;
    border-radius: 50px;
    &:hover {
        box-shadow: -0.5em -0.4em 0.5em #fff, 0.5em 0.4em 0.7em #c2c2c2;
    }
    &:focus {
        box-shadow: -0.5em -0.4em 0.5em #fff, 0.5em 0.4em 0.7em #bdbdbd;
    }
}
.inset-shadow-neu {
    border: solid 1px #efefef;
    box-shadow: #bdbdbd 2px 2px 4px 1px inset, #ffffff -2px -2px 3px 4px inset;
}
