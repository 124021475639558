.input-form-wrapper {
    height: 35px;
}
.ant-input-number-input {
    height: 35px;
}
.inline-form-input {
    width: 190px;
    border-radius: @border-radius-base 0 0 @border-radius-base;
    border: 0 !important;
    font-size: @font-size-s;
    letter-spacing: 0.2px;
}
.inline-form-input,
.inline-form-button {
    height: 35px;
}
