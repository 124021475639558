.comment-section-wrapper {
    border: @border-hr-light;
    border-radius: @border-radius-base;
    background-color: @background-light-color;
}
.discussion-comment {
    border-top: @border-hr;
}
.comment-input {
    flex: 1;
}
.comment-submit-btn {
    margin-top: -15px;
    margin-bottom: 0;
}
.comment-author {
    font-size: @font-size-xs;
    color: @text-color-secondary-dark;
    letter-spacing: 0.1px;
}
.comment-author:hover {
    color: @text-color-secondary-dark;
}
.comment-title {
    font-size: @font-size-xs;
    color: @text-color-secondary-medium;
    letter-spacing: 0.1px;
}
.comment-time {
    color: darken(@text-color-secondary-extra-light, 10%);
}
