.regular-top-banner-offer-text {
    font-size: @font-size-mp;
    color: @text-color-secondary-medium;
    font-weight: 500;
    letter-spacing: 0.2px;
    word-spacing: 2px;
}
.regular-top-banner-line1 {
    font-size: calc((@font-size-l + @font-size-xl) / 2);
}
.regular-top-banner-price {
    color: @primary-color;
    font-weight: 700;
    font-size: calc(1.1 * (@font-size-l + @font-size-xl) / 2);
}
.regular-top-banner-original-price {
    font-size: @font-size-xs;
    text-decoration: line-through;
    letter-spacing: 0.5px;
}
