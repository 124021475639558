.action-text {
    margin-left: 3px;
}
.no-like-btn,
.like-btn {
    color: @primary-color !important;
}
.follow-btn {
    color: @primary-color !important;
}
.ques-title {
    font-size: @font-size-s;
    color: @text-color-secondary-medium;
    letter-spacing: 0.1px;
}
.ques-title:hover {
    color: @primary-color;
}
.ques-action-items-wrapper {
    align-self: center;
}
.ques-signature-wrapper {
    margin-left: auto;
    .ques-signature-details {
        vertical-align: middle;

        .ques-time {
            font-size: @font-size-xs;
            color: darken(@text-color-secondary-extra-light, 15%);
        }
        .ques-author {
            font-size: @font-size-xs;
            color: lighten(@primary-color, 20%);
            letter-spacing: 0.1px;
        }
    }
    .ques-author-profile-pic {
        height: 100%;
    }
}
.discussion-ques-action-items {
    margin-right: 0.8rem !important;
    color: @text-color-secondary-light;
    span {
        color: @text-color-secondary-light;
    }
}
.large-action-icons {
    font-size: @font-size-s !important;
}
.discussion-ques:not(:last-child) {
    border-bottom: @border-hr;
}
.discussion-ques:hover,
.discussion-ques:focus {
    background-color: darken(@primary-light-color, 2%);
}
// editor
.editor-wrapper {
    border-radius: @border-radius-base;
}
.editor-toolbar {
    border-radius: @border-radius-base @border-radius-base 0 0;
}
.editor-textarea {
    border-radius: 0 0 @border-radius-base @border-radius-base;
}
.preview-heading {
    letter-spacing: 0.2px;
}
.preview-box {
    border: @border-input;
    border-radius: @border-radius-base;
    max-height: 500px;
    overflow: auto;
}
