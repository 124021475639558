@import '~antd/dist/antd.css';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import './variables';
@import './app';
@import './proVariables';
@import './home';
@import './footer';
@import './header';
@import './courseOverview';
@import './courseContent';
@import './courseDiscussion';
@import './courseVideo';
@import './courseSummary';
@import './courseCertificate';
@import './courseLanguage';
@import './discussionQuestion';
@import './coursePractice';
@import './practiceQuestion.less';
@import './profile.less';
@import './payment.less';
@import './billingHistory.less';
@import './UI/noData.less';
@import './UI/question.less';
@import './UI/comment.less';
@import './UI/previewStyle.less';
@import './UI/inlineForm.less';
@import './UI/inlineNumberForm.less';
@import './UI/referralCode.less';
@import './UI/socialLoginButtons.less';
@import './UI/loadingSpinner.less';
@import './UI/404.less';
@import './UI/rateCourseButton.less';
@import './Elements/neumorphism';
@import './Elements/floating';
@import './Elements/elements';
@import './practiceUI.less';
@import './about.less';
@import './Offer/offerTopBanner';
@import './Offer/regularOfferTopBanner';
@import './UI/languageTag.less';
