.certificate-button {
    width: 165px;
    min-height: 100%;
    font-size: calc((@font-size-xs + @font-size-s) / 2);
}
.certificate-icon {
    font-size: 17px;
}
.heading-certificate-modal {
    font-size: @font-size-l;
}
.text-certificate-modal {
    font-size: @font-size-s;
}
