.summary h1 {
    font-size: 1.9rem;
}
.summary h2 {
    margin-top: 1.5rem;
    font-size: 1.5rem;
}
.summary h3 {
    margin-top: 1.2rem;
    font-size: 1.2rem;
}
.summary h4 {
    margin-top: 1.05rem;
    font-size: 1.05rem;
}
.summary h5 {
    margin-top: 0.96rem;
    font-size: 0.96rem;
}
.summary h6 {
    margin-top: 0.95rem;
    font-size: 0.9rem;
}
.summary h1,
.summary h2,
.summary h3,
.summary h4,
.summary h5,
.summary h6 {
    color: @primary-dark-color;
}
.summary p {
    font-size: 0.87rem;
    color: lighten(@primary-dark-color, 14%);
    line-height: @summary-element-line-height;
}
.summary .p-heading {
    margin: 1.1rem 0 0.5rem;
    font-size: 1rem;
    font-weight: @font-weight-500;
    color: @primary-dark-color;
}
.summary .p-title {
    margin: 0.6rem 0 0.3rem;
    font-size: 0.87rem;
    font-weight: @font-weight-500;
    color: @primary-dark-color;
}
.summary .file-wrapper {
    filter: @summary-element-non-box-shadow;
    margin: 1.6rem auto;
}
.summary .p-filename {
    margin: 0.6rem 0 0;
    padding: 0 0.5rem;
    font-size: 0.84rem;
    font-weight: @font-weight-500;
    color: @primary-dark-color;
    background-color: @summary-component-background-color;
    border: @summary-element-border;
    border-radius: @border-radius-base @border-radius-base 0 0;
}
.summary .div-file {
    padding: 0.5rem 0.8rem;
    background-color: @white-color;
    border: @summary-element-border;
    border-radius: 0 0 @border-radius-base @border-radius-base;
    font-size: 0.8rem;
    white-space: pre;
}
.summary .p-important {
    margin: 1.3rem 0;
    padding: 0.8rem;
    font-size: 0.87rem;
    color: lighten(@primary-dark-color, 14%);
    background-color: lighten(@primary-color, 52%);
    border: solid 2px lighten(@primary-color, 48%);
    border-radius: @border-radius-base;
    line-height: @summary-element-line-height;
    box-shadow: @summary-element-box-shadow;
}
.summary hr {
    margin-top: 1.3rem;
    margin-bottom: 1.5rem;
    border-top: @border-hr-light;
}
.summary .hr-dashed {
    border: @summary-element-border-dashed;
}
.summary u {
    border-bottom: 3px solid darken(@secondary-color, 5%);
    text-decoration: none;
}
.summary mark {
    background-color: @secondary-color;
}
.summary del {
    text-decoration-color: darken(@secondary-color, 10%);
    text-decoration-thickness: 2px;
}
.summary a {
    text-decoration: none;
    box-shadow: @text-underline-box-shadow;
    color: lighten(@primary-dark-color, 14%);
}
.summary a:hover {
    box-shadow: @text-underline-box-shadow-hover;
}
.summary code {
    padding: 2px 8px;
    font-size: 0.8rem;
    color: darken(@secondary-color, 13%);
    background-color: lighten(@secondary-color, 48%);
    // border: @summary-element-border;
    border: darken(@secondary-color, 5%) 1px solid;
    border-radius: 4px;
}
.summary pre {
    font-size: 0.73rem;
    margin: 1.3rem 0;
    padding: 1rem;
    color: @white-color;
    background-color: @code-background-color;
    border-radius: @border-radius-base;
}
.summary pre code {
    padding: 0 !important;
    background-color: inherit;
    color: @code-text-color;
    border: none;
}
.summary .pre-no-code {
    code {
        color: @text-color-secondary-medium;
    }
    margin: 0.3rem 0 1.2rem;
    padding: 1rem;
    background-color: @summary-component-background-color;
    border: @summary-element-border;
    border-radius: @border-radius-base;
}
.summary blockquote {
    margin: 1.3rem 0;
    padding: 0.45rem 0.62rem;
    background-color: lighten(@primary-color, 52%);
    border-left: solid 3px @primary-color;
    font-size: 0.87rem;
    font-style: italic;
    color: lighten(@primary-dark-color, 14%);
    line-height: @summary-element-line-height;
    border-radius: @border-radius-base;
}
.summary figure {
    margin: 1.6rem 2rem;
    padding: 1rem 0 0 0;
    text-align: center;
    border: @border-hr-light;
    border-radius: @border-radius-base;
}
.summary figcaption {
    margin-top: 1rem;
    padding: 0.4rem 0.8rem;
    font-size: 0.75rem;
    font-weight: @font-weight-500;
    color: lighten(@primary-dark-color, 30%);
    border-top: @border-hr-light;
    p {
        margin-bottom: 0;
    }
}
.summary {
    img {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}
.summary ul {
    margin: 1.3rem 0;
}
.summary ol {
    margin: 1.3rem 0;
}
.summary li {
    margin-bottom: 0.6rem;
    font-size: 0.87rem;
    color: lighten(@primary-dark-color, 14%);
}
.summary {
    table {
        margin: 1.6rem auto;
        overflow: auto;
        border: none;
        box-shadow: @summary-element-box-shadow;
        width: 100%;
        border-collapse: separate !important;
        border-spacing: 0;
        border-radius: @border-radius-base;
        tr:first-child {
            th:first-child {
                border-top-left-radius: @border-radius-base;
            }
        }

        tr:first-child {
            th:last-child {
                border-top-right-radius: @border-radius-base;
            }
        }
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: @border-radius-base;
            }
        }

        tr:last-child {
            td:last-child {
                border-bottom-right-radius: @border-radius-base;
            }
        }
        tr:hover {
            background-color: darken(@primary-light-color, 2%);
        }
        td {
            border: @border-table-light;
        }
        th {
            border: @border-table-header;
        }
    }
}
.summary th {
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    font-weight: @font-weight-500;
    color: @white-color;
    background-color: @primary-color;
}
.summary table td {
    padding: 0.5rem 0.5rem;
    font-size: 0.79rem;
    color: lighten(@primary-dark-color, 14%);
}
.summary caption {
    caption-side: bottom;
    padding: 0.4rem 0 0 0;
    font-size: 0.8rem;
    font-weight: @font-weight-500;
    text-align: center;
}
// table without header
.summary .table-no-header {
    width: 100%;
    tr:first-child {
        td:first-child {
            border-top-left-radius: @border-radius-base;
        }
    }

    tr:first-child {
        td:last-child {
            border-top-right-radius: @border-radius-base;
        }
    }
    tr:last-child {
        td:first-child {
            border-bottom-left-radius: @border-radius-base;
        }
    }

    tr:last-child {
        td:last-child {
            border-bottom-right-radius: @border-radius-base;
        }
    }
}
// table without rows
.summary .table-no-row {
    width: 50%;
    tr:first-child {
        td:first-child {
            border-top-left-radius: @border-radius-base;
        }
    }
    tr:first-child {
        td:last-child {
            border-top-right-radius: @border-radius-base;
        }
    }
    tr:last-child {
        td:first-child {
            border-bottom-left-radius: @border-radius-base;
        }
    }

    tr:last-child {
        td:last-child {
            border-bottom-right-radius: @border-radius-base;
        }
    }
    tr:hover {
        background-color: inherit !important;
    }
    td:hover {
        background-color: darken(@primary-light-color, 2%);
    }
}
// code
.summary .code-multiple-lang ul {
    list-style: none;
    margin: 1.3rem 0 0 0;
    padding: 0;

    li:not(:first-of-type) {
        margin-left: 3px;
    }
}
.summary .code-multiple-lang ul li {
    display: inline-block;
    color: @white-color;
    font-size: 0.8rem;
    font-weight: @font-weight-500;
    letter-spacing: 0.6px;
    background-color: @primary-color;
    padding: 0.1rem 0.8rem;
    margin-bottom: 0;
    border-radius: @border-radius-base @border-radius-base 0 0;
    cursor: pointer;
}
.summary .code-multiple-lang ul li.active-tab {
    color: lighten(@primary-color, 30%);
    background-color: @code-background-color;
}
.summary .code-multiple-lang pre {
    margin: 0 0 1.3rem 0;
    padding: 1.2rem 1rem;
    color: @white-color;
    background-color: @code-background-color;
    border-radius: 0 @border-radius-base @border-radius-base @border-radius-base;
    display: none;
}
.summary .code-multiple-lang pre.active-code {
    display: block;
}
