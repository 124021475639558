// card
.default-card-float {
    border-radius: @border-radius-base;
    border: solid 1px #e0e0e0;
    box-shadow: -.2em 1em 1.5em #dedede,
    .2em .3em 1.5em #dedede,
    -.2em -.2em 1.5em #dedede,
    .2em -.2em 1.5em #dedede;
}
// floating shadow (large elements)
.large-shadow-float {
    border: solid 1px #e0e0e0;
    box-shadow: -.2em 1em 1.5em #dedede,
    .2em .3em 1.5em #dedede,
    -.2em -.2em 1.5em #dedede,
    .2em -.2em 1.5em #dedede;
}
// floating shadow (small elements)
.small-shadow-float {
    // TODO
}