.rate-course-btn-primary {
    background-color: @primary-color;
    border-radius: @border-radius-base;
}
.rate-course-btn-heading {
    font-size: @font-size-m;
    font-weight: @font-weight-500;
    letter-spacing: 0.2px;
    color: white;
}
