.discussion-ques-wrapper {
    width: 70%;
}
.discussion-ques-detail h1 {
    font-size: @font-size-xl;
}
.action-icon-wrapper {
    font-size: @font-size-s;
}
.action-icon-wrapper:hover,
.action-icon-wrapper:active {
    color: @primary-color-hover;
}
.answer-count {
    font-size: @font-size-m;
    font-weight: @font-weight-500;
    letter-spacing: 0.3px;
    border-bottom: @border-dark;
}
.discussion-menu-icon {
    font-size: @font-size-l;
    color: @primary-color;
}
.discussion-ques-menu {
    top: @padding-s;
    right: 0px;
}
.discussion-ans-menu {
    top: calc(2 * @padding);
    right: 0px;
}
//answer
.answer-wrapper:not(:last-child) {
    border-bottom: @border-hr;
}
// Media
@media (max-width: @breakpoint-mob) {
    .discussion-ques-wrapper {
        width: 90%;
    }
}
