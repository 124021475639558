.practice-wrapper .ant-tabs-nav-wrap {
    display: flex;
    flex-wrap: wrap;
}
.practice-wrapper .ant-tabs-tab {
    background-color: @primary-light-color !important;
}
.practice-level {
    width: 150px;
    height: 45px;
    font-size: @font-size-s;
    letter-spacing: 0.1px;
    font-weight: @font-weight-500;
}
.practice-ques-wrap {
    font-size: @font-size-s;
    letter-spacing: 0.2px;
    a {
        width: calc(100% - @practice-index-dim);
    }
}
.practice-ques-wrap:not(:last-child) {
    border-bottom: @border-hr;
}
.serial-no {
    display: inline-block;
    width: @practice-index-dim;
    height: @practice-index-dim;
    color: @primary-color;
    font-weight: @font-weight-700;
    background-color: lighten(@primary-color, 46%);
    border-radius: 50%;
    line-height: 30px;
    flex-shrink: 0;
}
.practice-ques-wrapper {
    width: 100%;
}
.practice_ques_choice-wrapper {
    position: absolute;
    top: 0;
}
.practice-ques-choices .ant-checkbox-wrapper {
    position: relative;
    font-size: @font-size-s;
}
.practice-ques-choices .ant-checkbox {
    position: absolute;
    top: 5px;
}
.practice-ques-choices .ant-checkbox + span {
    padding-left: 25px;
}
.practice_ques_choice_index {
    position: absolute;
    top: 0;
}
.default-practice-option {
    color: lighten(@primary-dark-color, 14%);
}
.practice-ques-option {
    padding-left: 20px;
    white-space: pre;
}
.practice-ques-choices {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-wrapper .ant-checkbox-inner,
    .ant-checkbox .ant-checkbox-inner,
    .ant-checkbox-input + .ant-checkbox-inner {
        border-radius: 2px !important;
    }
}
.practice-submit-btn:disabled {
    color: white;
    background-color: lighten(@primary-color, 10%);
    :hover {
        color: white;
        background-color: lighten(@primary-color, 10%);
    }
}
.practice-submit-btn:disabled:hover {
    color: white;
    background-color: lighten(@primary-color, 10%);
}
.practice-ques-icons {
    display: block !important;
}
.practice-ans {
    background-color: @white-color;
    border: solid 0.7px #cfcfcf;
    border-radius: @border-radius-base;
    p {
        margin-bottom: 0;
    }
}
// Media
@media (max-width: 700px) {
    .practice-level {
        width: 70px;
        height: 40px;
        padding: 0 @padding-l;
    }
    .practice-wrapper .ant-tabs-tab {
        padding: 0 @padding-s !important;
    }
}
